// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container {
  max-width: 800px;
  margin: 0 auto;
}
.payment-container .stripe-payment {
  display: flex;
}
.payment-container .stripe-payment [class^="payment-"] {
  flex: 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/view/Payment/payment.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;AAHA;EAII,aAAA;AAEJ;AANA;EAMM,YAAA;AAGN","sourcesContent":[".payment-container {\n  max-width: 800px;\n  margin: 0 auto;\n  .stripe-payment {\n    display: flex;\n    [class^=\"payment-\"] {\n      flex: 1 auto;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useLocation } from 'react-router-dom'
import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Button } from 'antd'
import './payment.less'
import { useStripePayment } from './payment.hook'

const StripePayment = ( { clientSecret } ) => {
    const { handleStripePayment } = useStripePayment( clientSecret )

    return (
        <form onSubmit={ handleStripePayment }>
            <div style={ { minHeight: '500px' } }>
                <PaymentElement/>
            </div>

            <Button type="primary" htmlType="submit">
                提交支付
            </Button>
        </form>
    )
}

const Payment = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams( location.search )
    const stripeKey = searchParams.get( 'stripeKey' )
    const client = searchParams.get( 'client' )
    const stripePromise = stripeKey ? loadStripe( stripeKey ) : ''
    return (
        <div className="payment-container">
            {
                ( stripeKey && client )
                    ? <div className="stripe-payment">
                        <div className="payment-info">
                            <p>购物</p>
                            <p>$0.0</p>
                        </div>
                        <div className="payment-method">
                            <h2>选择支付方式</h2>
                            <Elements
                                stripe={ stripePromise }
                                options={ { client } }>
                                <StripePayment clientSecret={ client }/>
                            </Elements>
                        </div>
                    </div>
                    : <p>加载中...</p>
            }
        </div>
    )
}

export default Payment

import { useStripe, useElements } from '@stripe/react-stripe-js';

export const useStripePayment = (clientSecret) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleStripePayment = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error("Stripe.js 尚未加载");
            return;
        }

        // 提交表单以验证输入
        const { error: submitError } = await elements.submit();

        if (submitError) {
            console.error("提交错误:", submitError);
            return;
        }

        // 然后调用 stripe.confirmPayment()
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:9000/user?result=success', // 支付成功后的重定向 URL
            },
            clientSecret,
        });

        if (error) {
            console.error("支付错误:", error);
            // 处理错误逻辑
        } else {
            console.log('支付成功！');
            // 处理支付成功的逻辑
        }
    };

    return { handleStripePayment };
};

import React, { useEffect, useState } from 'react'
import { Button, Form, Tooltip, message } from 'antd'
import to from 'await-to-js'
// store
import { useSelector } from 'react-redux'

// hooks
import useCopyClipboard from '@/hooks/copyClipboard'
// api
import {
    referralAccounts,
    withdraw,
    createCode,
    channelManager,
    registeredUser,
    recommendedOrder
} from '@/api/recommend'

// 推荐账户
export const useReferralAccounts = ( t ) => {

    const [ form ] = Form.useForm()
    const [ referralAccountsData, setReferralAccountsData ] = useState( {} )
    const [ visible, setVisible ] = useState( false )
    const [ loading, setLoading ] = useState( false )


    const rulesForm = {
        withdrawalAmount: [
            { required: true, message: t( 'recommend.referralAccountsFrom.withdrawalAmountPlaceholder' ) }
        ],
        paymentChannel: [
            { required: true, message: t( 'recommend.referralAccountsFrom.paymentChannelPlaceholder' ) }
        ],
        paymentAccount: [
            { required: true, message: t( 'recommend.referralAccountsFrom.paymentAccountPlaceholder' ) }
        ],
        accountName: [
            { required: true, message: t( 'recommend.referralAccountsFrom.accountNamePlaceholder' ) }
        ],
    }

    // 弹窗控制
    const handleModalControl = () => {
        setVisible( !visible )
        form.resetFields()
    }

    // 表单提交
    const handleFormSubmit = async ( values ) => {
        setLoading( true )
        const [ err, res ] = await to( withdraw( values ) )
        setLoading( false )
        if ( err ) return
        const { msg } = res
        handleModalControl()
        message.success( msg )
    }

    // 推荐账户 - 请求
    const sendReferralAccounts = async () => {
        const [ err, res ] = await to( referralAccounts() )
        if ( err ) return
        const { data } = res
        setReferralAccountsData( data )
    }

    useEffect( () => {
        sendReferralAccounts().then()
    }, [] )

    return {
        form, loading, referralAccountsData, visible, rulesForm, handleModalControl, handleFormSubmit
    }
}

// 推荐信息
export const useRecommendations = ( t ) => {

    const affiliate = useSelector( state => state.userStore.affiliate )
    const { copyClipboard } = useCopyClipboard()

    const [ form ] = Form.useForm()
    const [ visible, setVisible ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ tableData, setTableData ] = useState( [] )
    const [ pagination, setPagination ] = useState( { current: 1, pageSize: 10, total: 0 } )

    // 推荐地址
    const copyAddress = `${ affiliate.basicUrl }?code=${ affiliate.defaultCode }`

    // 弹窗控制
    const handleModalControl = () => {
        setVisible( !visible )
        form.resetFields()
    }

    // 表单提交
    const handleFormSubmit = async ( values ) => {
        setLoading( true )
        const [ err, res ] = await to( createCode( values ) )
        setLoading( false )

        if ( err ) return

        const { msg } = res
        handleModalControl()
        await sendChannelManager()
        message.success( msg )
    }

    const columns = [
        {
            title: t( 'recommend.recommendations.columns.ChannelName' ),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t( 'recommend.recommendations.columns.promotionCode' ),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t( 'recommend.recommendations.columns.clicks' ),
            dataIndex: 'clicks',
            key: 'clicks',
        },
        {
            title: t( 'recommend.recommendations.columns.visitors' ),
            dataIndex: 'visitors',
            key: 'visitors',
        },
        {
            title: t( 'recommend.recommendations.columns.registrations' ),
            dataIndex: 'registrations',
            key: 'registrations',
        },
        {
            title: t( 'recommend.recommendations.columns.fullAddress' ),
            dataIndex: 'fullAddress',
            key: 'fullAddress',
        },
        {
            title: t( 'recommend.recommendations.columns.actions' ),
            dataIndex: 'actions',
            key: 'actions',
            render: ( _, { fullAddress } ) => <Button
                onClick={ () => copyClipboard( fullAddress ) }>{ t( 'recommend.recommendations.copy' ) }</Button>

        },
    ]

    // 渠道管理 - 请求
    const sendChannelManager = async ( pageData ) => {
        const [ err, res ] = await to( channelManager( pageData ) )
        if ( err ) return
        const { data: { page, size, total, list } } = res
        setTableData( list || [] )
        setPagination( {
            ...pagination,
            current: page,
            pageSize: size,
            total

        } )
    }
    const handlePagination = async ( data ) => {
        await sendChannelManager( { page: data.current, size: data.pageSize } )
    }

    useEffect( () => {
        sendChannelManager( { page: pagination.current, size: pagination.pageSize } ).then()
    }, [] )

    return {
        form,
        loading,
        visible,
        copyAddress,
        handleModalControl,
        handleFormSubmit,
        columns,
        tableData,
        pagination,
        handlePagination
    }
}

export const useRegisteredUser = ( t ) => {

    const [ tableData, setTableData ] = useState( [] )
    const [ pagination, setPagination ] = useState( { current: 1, pageSize: 10, total: 0 } )
    const [ status, setStatus ] = useState( 6 )

    // 状态下拉 options
    const optionsList = [
        { value: 1, label: t( 'recommend.timeSelect.1' ) },
        { value: 2, label: t( 'recommend.timeSelect.2' ) },
        { value: 3, label: t( 'recommend.timeSelect.3' ) },
        { value: 4, label: t( 'recommend.timeSelect.4' ) },
        { value: 5, label: t( 'recommend.timeSelect.5' ) },
        { value: 6, label: t( 'recommend.timeSelect.6' ) },
    ]

    const columns = [
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'UID',
            width: 80,
        },
        {
            title: t( 'recommend.registeredUser.columns.userId' ),
            dataIndex: 'userinfo',
            key: 'userinfo',
            ellipsis: {
                showTitle: false,
            },
            render: userinfo => <Tooltip placement="topLeft" title={ userinfo }>{ userinfo }</Tooltip>
        },
        {
            title: t( 'recommend.registeredUser.columns.registrationTime' ),
            dataIndex: 'registrationTime',
            key: 'registrationTime',
            ellipsis: {
                showTitle: false,
            },
            render: registrationTime => <Tooltip placement="topLeft"
                                                 title={ registrationTime }>{ registrationTime }</Tooltip>
        },
        {
            title: t( 'recommend.registeredUser.columns.promotionCode' ),
            dataIndex: 'code',
            key: 'code',
        },
    ]
    const sendRegisteredUser = async ( pageData ) => {
        const [ err, res ] = await to( registeredUser( pageData ) )
        if ( err ) return
        const { data: { page, size, total, list } } = res
        setTableData( list || [] )
        setPagination( {
            current: page,
            pageSize: size,
            total
        } )
    }

    // 页码切换
    const handlePagination = async ( data ) => {
        await sendRegisteredUser( {
            page: data.current,
            size: data.pageSize,
            status
        } )
    }

    // 状态选择
    const handleSelectChange = async ( value ) => await sendRegisteredUser( {
        page: pagination.current,
        size: pagination.pageSize,
        status: value
    } )


    useEffect( () => {
        sendRegisteredUser( { page: pagination.current, size: pagination.pageSize } ).then()
    }, [] )

    return { columns, tableData, pagination, handlePagination, optionsList, handleSelectChange }
}

// 推荐订单
export const useRecommendedOrder = ( t ) => {

    const [ tableData, setTableData ] = useState( [] )
    const [ pagination, setPagination ] = useState( { current: 1, pageSize: 10, total: 0 } )
    const [ status, setStatus ] = useState( 6 )

    // 状态下拉 options
    const optionsList = [
        { value: 1, label: t( 'recommend.timeSelect.1' ) },
        { value: 2, label: t( 'recommend.timeSelect.2' ) },
        { value: 3, label: t( 'recommend.timeSelect.3' ) },
        { value: 4, label: t( 'recommend.timeSelect.4' ) },
        { value: 5, label: t( 'recommend.timeSelect.5' ) },
        { value: 6, label: t( 'recommend.timeSelect.6' ) },
    ]

    const columns = [
        {
            title: t( 'recommend.recommendedOrder.columns.userId' ),
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: t( 'recommend.recommendedOrder.columns.firstOrder' ),
            dataIndex: 'firstOrder',
            key: 'firstOrder',
            render: firstOrder => firstOrder ? t( 'recommend.recommendedOrder.firstOrderEnum.1' ) : t( 'recommend.recommendedOrder.firstOrderEnum.0' )

        },
        {
            title: t( 'recommend.recommendedOrder.columns.orderNo' ),
            dataIndex: 'orderNo',
            key: 'orderNo',
            ellipsis: {
                showTitle: false,
            },
            render: orderNo => <Tooltip placement="topLeft" title={ orderNo }>{ orderNo }</Tooltip>
        },
        {
            title: t( 'recommend.recommendedOrder.columns.commissionAmount' ),
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            render: commissionAmount => `$${ commissionAmount }`
        },
        {
            title: t( 'recommend.recommendedOrder.columns.orderType' ),
            dataIndex: 'level',
            key: 'level',
            render: level => {
                const levelEnums = [ null, t( 'recommend.recommendedOrder.firstOrder' ), t( 'recommend.recommendedOrder.secondOrder' ) ]
                let text = levelEnums[ level ] || t( 'common.unknown' )
                return text
            }
        },
        {
            title: t( 'recommend.recommendedOrder.columns.createdAt' ),
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: {
                showTitle: false,
            },
            render: createdAt => <Tooltip placement="topLeft" title={ createdAt }>{ createdAt }</Tooltip>,
        }
    ]
    // 推荐订单数据 - 请求
    const sendRecommendedOrder = async ( pageData ) => {
        const [ err, res ] = await to( recommendedOrder( pageData ) )
        if ( err ) return
        const { data: { page, size, total, list } } = res
        setTableData( list || [] )
        setPagination( {
            ...pagination,
            current: page,
            pageSize: size,
            total

        } )
    }
    // 页码切换
    const handlePagination = async ( data ) => await sendRecommendedOrder( {
        page: data.current,
        size: data.pageSize,
        status
    } )

    // 状态选择
    const handleSelectChange = async ( value ) => await sendRecommendedOrder( {
        page: pagination.current,
        size: pagination.pageSize,
        status: value
    } )


    useEffect( () => {
        sendRecommendedOrder( { page: pagination.current, size: pagination.pageSize } ).then()
    }, [ tableData.length, pagination.current, pagination.pageSize, status ] )

    return { columns, tableData, pagination, handlePagination, optionsList, handleSelectChange }
}

import to from 'await-to-js'
import { useState } from 'react'
import { useTranslation } from "react-i18next"
import { deactivateAccount } from '../../api/deactivate'
import { message } from 'antd'

const useDeactivate = () => {
    const {t} = useTranslation()
    const [ open, setOpen ] = useState(false)
    const [ deactivateStatus, setDeactivateStatus ] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [ params, setParams ] = useState({
        account: '',
        password: ''
    })

    const rulesForm = {
        account: [
            {
                required: true,
                message: t('deactivate.accountTips')
            }
        ],
        password: [
            {
                required: true,
                message: t('login.passwordPlaceholder')
            }
        ]
    }

    const hideModal = () => {
        setOpen(false)
    }

    const openModal = (formData) => {
        console.log("formData", formData)
        setOpen(true)
        setParams(formData)
        console.log('params:', params)
    }

    const confirmDeactivate = async () => {
        setConfirmLoading(true)
        const [ err, res ] = await to(deactivateAccount(params))
        setConfirmLoading(false)

        if(err || res?.code !== 200) {
            message.error( t( res?.message || 'deactivate.failureTips') )
            return
        }
        setOpen(false)
        setDeactivateStatus(true)
        message.success(t('deactivate.successTips'))
    }

    return {
        open,
        rulesForm,
        confirmLoading,
        deactivateStatus,

        openModal,
        hideModal,
        confirmDeactivate
    }
}

export default useDeactivate

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-instructions {
  max-width: 1200px;
  margin: 20px auto 0;
}
.sign-in-instructions article {
  margin-top: 30px;
}
.sign-in-instructions dl {
  margin-bottom: 24px;
}
.sign-in-instructions dt {
  color: #555;
  font-weight: 700;
  margin-bottom: 14px;
}
.sign-in-instructions dd {
  line-height: 20px;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/view/SignInInstructions/sign-in-instructions.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;AACF;AAHA;EAII,gBAAA;AAEJ;AANA;EAQI,mBAAA;AACJ;AATA;EAWI,WAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAdA;EAgBI,iBAAA;EACA,eAAA;AACJ","sourcesContent":[".sign-in-instructions{\n  max-width: 1200px;\n  margin: 20px auto 0;\n  article {\n    margin-top: 30px;\n  }\n\n  dl {\n    margin-bottom: 24px;\n  }\n  dt {\n    color: #555;\n    font-weight: 700;\n    margin-bottom: 14px;\n  }\n  dd {\n    line-height: 20px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

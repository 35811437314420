// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUs-page .content {
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 1200px;
  margin: 20px auto;
  min-height: calc(100vh - 64px - 40px - 67px);
}
@media (max-width: 576px) {
  .contactUs-page .content {
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: calc(100vh - 64px - 40px - 101px);
  }
}
@media (min-width: 992px), (min-width: 768px), (min-width: 576px) {
  .contactUs-page .content {
    padding: 100px;
  }
}
.contactUs-page p {
  margin-top: 4px !important;
  line-height: 1.5 !important;
}
.contactUs-page span {
  line-height: 1.76 !important;
}
.contactUs-page ul,
.contactUs-page ol {
  margin: 0;
  padding: 0;
  list-style: disc;
}
`, "",{"version":3,"sources":["webpack://./src/view/ContactUs/contactUs.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,SAAA;EACA,sBAAA;EACA,iBAAA;EACA,iBAAA;EACA,4CAAA;AAAJ;AAGE;EAAA;IAEI,mBAAA;IACA,uBAAA;IACA,UAAA;IACA,6CAAA;EADJ;AACF;AAIE;EAAA;IAII,cAAA;EAJJ;AACF;AApBA;EA4BI,0BAAA;EACA,2BAAA;AALJ;AAxBA;EAiCI,4BAAA;AANJ;AA3BA;;EAqCI,SAAA;EACA,UAAA;EACA,gBAAA;AANJ","sourcesContent":[".contactUs-page {\n  .content {\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n    max-width: 1200px;\n    margin: 20px auto;\n    min-height: calc(100vh - 64px - 40px - 67px);\n  }\n\n  @media (max-width: 576px) {\n    .content {\n      align-items: center;\n      justify-content: center;\n      padding: 0;\n      min-height: calc(100vh - 64px - 40px - 101px);\n\n    }\n  }\n  @media (min-width: 992px) ,\n  (min-width: 768px) ,\n  (min-width: 576px) {\n    .content {\n      padding: 100px;\n    }\n  }\n\n  p {\n    margin-top: 4px !important;\n    line-height: 1.5 !important;\n  }\n\n  span {\n    line-height: 1.76 !important;\n  }\n\n  ul, ol {\n    margin: 0;\n    padding: 0;\n    list-style: disc;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

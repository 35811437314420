import React from 'react'
import './sign-in-instructions.less'

import { useTranslation } from 'react-i18next'

const SignInInstructions = () => {
    const { t } = useTranslation()


    return (
            <div className="sign-in-instructions">
                <h1>{ t( 'sign-in-instructions.title' ) }</h1>
                <article>
                    <dl>
                        <dt>{ t( 'sign-in-instructions.rules.title' ) }</dt>
                        <dd>{ t( 'sign-in-instructions.rules.a' ) }</dd>
                        <dd>{ t( 'sign-in-instructions.rules.b' ) }</dd>
                        <dd>{ t( 'sign-in-instructions.rules.c' ) }</dd>
                    </dl>
                    <dl>
                        <dt>{ t( 'sign-in-instructions.rewards.title' ) }</dt>
                        <dd>{ t( 'sign-in-instructions.rewards.a' ) }</dd>
                        <dd>{ t( 'sign-in-instructions.rewards.b' ) }</dd>
                        <dd>{ t( 'sign-in-instructions.rewards.c' ) }</dd>
                    </dl>
                    <dl>
                        <dt>{ t( 'sign-in-instructions.feedback.title' ) }</dt>
                        <dd>{ t( 'sign-in-instructions.feedback.desc' ) }</dd>
                    </dl>
                </article>
            </div>
    )
}

export default SignInInstructions

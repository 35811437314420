import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Menu, Popover, Dropdown } from 'antd'
import { enquireScreen } from 'enquire-js'

// store
import { useSelector, useDispatch } from 'react-redux'
import { clearUserData } from '../../store/modules/userStore'

// images
import LOGO_URL from 'images/logo.png'
import USER_URL from 'images/head/user.svg'
import MENU_URL from 'images/head/menu.svg'

import './style.less'

const Header = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const isAffiliate = useSelector( ( state ) => state.userStore.isAffiliate )
    const userToken = useSelector( state => state.userStore.token )
    const userInfo = useSelector( state => state.userStore.userInfo )

    const [ menuVisible, setMenuVisible ] = useState( false )
    const [ menuMode, setMenuMode ] = useState( 'horizontal' )

    const handleShowMenu = () => setMenuVisible( !menuVisible )

    const onMenuVisibleChange = ( visible ) => setMenuVisible( visible )

    const location = useLocation()

    // 登出
    const handleLogout = () => {
        dispatch( clearUserData() )
        history.push( '/' )
    }

    const menu = (
        <Menu mode={ menuMode } id="nav" key="nav" selectedKeys={ [ location.pathname ] } inlineCollapsed={false}>
            <Menu.Item key="/">
                <Link to="/">{ t( 'header.home' ) }</Link>
            </Menu.Item>
            <Menu.Item key="/affiliate">
                <Link to="/affiliate">{ t( 'affiliate.navTitle' ) }</Link>
            </Menu.Item>
            {
                !userToken
                && <Menu.Item key="/login">
                    <Link to="/login">{ t( 'login.title' ) }</Link>
                </Menu.Item>

            }
            {
                ( menuMode === 'inline' && userToken )
                && (
                    <>
                        <Menu.Item key="/user">
                            <Link to="/user">{ t( 'user.personalCenter' ) }</Link>
                        </Menu.Item>
                        {
                            isAffiliate && <Menu.Item key="/recommend">
                                <Link to="/recommend">{ t( 'recommend.title' ) }</Link>
                            </Menu.Item>
                        }
                        <Menu.Item key="logout">
                            <span onClick={ handleLogout }>{ t( 'login.logout' ) }</span>
                        </Menu.Item>
                    </>
                )
            }

        </Menu>
    )

    const dropdownItems = [
        {
            label: ( <Link to="/user"
                           style={ { textAlign: 'center', display: 'block' } }>{ t( 'user.personalCenter' ) }</Link> ),
            key: 'personalCenter',
        },
        isAffiliate && {
            label: ( <Link to="/recommend"
                           style={ { textAlign: 'center', display: 'block' } }>{ t( 'recommend.title' ) }</Link> ),
            key: 'recommend',
        },
        {
            label: ( <span style={ { textAlign: 'center', display: 'block' } }
                           onClick={ handleLogout }>{ t( 'login.logout' ) }</span> ),
            key: 'logout'
        }
    ]

    useEffect( () => {
        enquireScreen( ( b ) => setMenuMode( b ? 'inline' : 'horizontal' ) )
    }, [ menuMode ] )

    return (
        <div id="header" className="header">
            <Row>
                <Col xxl={ 4 } xl={ 5 } lg={ 8 } md={ 8 } sm={ 12 } xs={ 12 }>
                    <Link id="logo" to="/">
                        <img src={ LOGO_URL } alt="logo"/>
                    </Link>
                </Col>
                <Col xxl={ 20 } xl={ 19 } lg={ 16 } md={ 16 } sm={ 12 } xs={ 12 }>
                    <div className="header-meta">
                        <div id="menu">
                            { menuMode === 'horizontal'
                                ? menu
                                : <Popover
                                    placement="bottom"
                                    content={ menu }
                                    trigger="click"
                                >
                                    <img src={ MENU_URL } alt="menu" onClick={ handleShowMenu }/>
                                </Popover>
                            }
                        </div>
                        {
                            ( menuMode === 'horizontal' && userToken )
                            && <Dropdown menu={ { items: dropdownItems } }>
                                <figure>
                                    <img width={24} src={ userInfo.avatar || USER_URL } alt=""/>
                                    <figcaption style={{marginLeft: '4px'}}>{ userInfo.email }</figcaption>
                                </figure>
                            </Dropdown>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Header

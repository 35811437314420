import React, { useEffect, useState } from 'react'
import { omit, pick } from 'lodash-es'
import to from 'await-to-js'
import { useHistory } from 'react-router-dom'

// api
import { productList, productEquity, purchaseMembership } from '../../api/user'
// icon
import Fault from '../../components/Icon/Fault'
import Correct from '../../components/Icon/Correct'

export const useIdentification = () => {
    return {}
}

export const useServiceBundle = () => {
    const history = useHistory()


    const [ productTabs, setProductTabs ] = useState( [] )
    const [ productLists, setProductLists ] = useState( [] )
    const [ productData, setProductData ] = useState( [] )
    const [ productLoading, setProductLoading ] = useState( false )
    const [ serviceTab, setServiceTab ] = useState( 0 )
    const [ productId, setProductId ] = useState( 0 )

    const [ columns, setColumns ] = useState( [] )
    const [ dataSource, setDataSource ] = useState( [] )
    const [ tableLoading, setTableLoading ] = useState( false )
    // 用户选中的 套餐tabs
    const handleServiceTab = ( val ) => {
        setServiceTab( val )
        setProductData( productLists[ val ] )
        setProductId( productLists[ val ][ 0 ].id )
    }
    // 用户选中的套餐
    const handleProduct = ( val ) => {
        setProductId( val )
    }
    // 计算每天价格
    const computedPrice = ( price, days ) => {
        return ( ( price * 100 / days ) / 100 ).toFixed( 2 )
    }

    // 获取会员购买列表 - 请求
    const sendProductList = async () => {
        setProductLoading( true )
        const [ err, res ] = await to( productList() )
        setProductLoading( false )
        if ( err ) return
        const { data: { list } } = res
        setProductTabs( list.map( items => omit( items, [ 'products' ] ) ) )
        setProductLists( list.map( items => pick( items, [ 'products' ] ).products ) )
        setProductData( list[ 0 ].products )
        setProductId( list[ 0 ].products[ 0 ].id )
    }

    // 获取会员权益列表 - 请求
    const sendProductEquity = async ( val ) => {
        setTableLoading( true )
        const [ err, res ] = await to( productEquity() )
        setTableLoading( false )
        if ( err ) return
        const { data } = res

        // 动态生成 groupIdMap 函数
        function generateGroupIdMap( list ) {
            const keyNames = [ "general", "vip", "svip" ] // 预定义会员等级
            const groupIdMap = {}

            // 遍历列表，动态生成 groupIdMap
            list.forEach( ( item, index ) => {
                const key = keyNames[ index ] || `level${ index + 1 }` // 若超出预定义键名，使用动态名称
                groupIdMap[ item.group_id ] = key

            } )

            return groupIdMap
        }

        // 替换值为 ❌ 或 ✅ 的函数
        function replaceWithIcons( value ) {
            return value === 1 ? <Correct/> : <Fault/>
        }

        function transformData( originalData ) {
            // 动态生成 groupIdMap
            const groupIdMap = generateGroupIdMap( originalData.list )

            // 初始化 columnsData 数组
            const columnsData = [
                { title: "会员权益", dataIndex: "equity", key: "equity" }
            ]

            // 动态生成订阅的列
            originalData.list.forEach( item => {
                columnsData.push( {
                    title: item.name, // 使用订阅的 name 作为 title
                    dataIndex: groupIdMap[ item.group_id ], // 使用动态生成的 key
                    key: groupIdMap[ item.group_id ] // key 和 dataIndex 相同
                } )
            } )

            const dataSource = []

            // 遍历 columns，生成每个权益项的数据
            for ( const key in originalData.columns ) {
                const row = { equity: originalData.columns[ key ] } // 使用 columns 的值作为 equity

                // 填充每个订阅类型的数据
                originalData.list.forEach( item => {
                    const memberKey = groupIdMap[ item.group_id ] // 通过 group_id 找到对应的会员类型
                    // 替换无限流量、微信视频、影音加速、游戏加速的值
                    if ( [ "flow", "wechat", "video", "game" ].includes( key ) ) {
                        row[ memberKey ] = replaceWithIcons( item[ key ] ) // 替换图标
                    } else {
                        row[ memberKey ] = item[ key ] // 保持原值
                    }
                    row.key = key
                } )

                dataSource.push( row ) // 将每一行数据加入 dataSource
            }

            return { columnsData, dataSource }
        }

        // 调用方法转换数据
        const { columnsData, dataSource } = transformData( data )
        setColumns( columnsData )
        setDataSource( dataSource )
    }

    // 会员订阅 - 请求
    const handlePurchaseMembership = async () => {
        setProductLoading( true )
        const jumpPath = location.href
        const params = {
            product_id: productId,
            success_url: `${ jumpPath }?result=success`,
            cancel_url: `${ jumpPath }?result=error`
        }
        const [ err, res ] = await to( purchaseMembership( params ) )
        setProductLoading( false )
        if ( err ) return
        const { data } = res
        history.push( `/payment?stripeKey=${data.stripe.publishable_key}&client=${data.stripe.client_secret}` )
    }

    useEffect( () => {
        sendProductList().then()
        sendProductEquity().then()
    }, [] )

    return {
        serviceTab,
        productTabs,
        productData,
        productId,
        handleServiceTab,
        handleProduct,
        computedPrice,
        columns,
        dataSource,
        productLoading,
        tableLoading,
        handlePurchaseMembership
    }
}

import React from 'react'

import LayoutHeader from '../Header'
import LayoutFooter from '../Footer'

const Layout = ( { children } ) => {

    return (
        <>
            <LayoutHeader/>
            <div style={{minHeight: 'calc(100vh - 131px)'}}>
                { children }
            </div>
            <LayoutFooter/>
        </>
    )
}
export default Layout

import { get } from 'lodash-es'
import { message } from 'antd'
// store
import { store } from '../../store'
import { clearUserData } from '../../store/modules/userStore'
//
import { useLocation } from 'react-router-dom'

const goNotAuth = () => location.replace( '/login' )
const redirectLogin = () => {
    store.dispatch( clearUserData() )
    location.replace( '/login' )
}

// 错误处理
const codeHandleFn = ( data ) => {
    // 错误调转处理集合
    const codeHandleMap = new Map( [
        [ 40002, goNotAuth ],
        [ 40003, redirectLogin ],
        [ 40004, redirectLogin ]
    ] )

    message.error( data.msg )

    if ( codeHandleMap.has( data.code ) ) {
        const handleFn = codeHandleMap.get( data.code )
        handleFn && handleFn()
    } else {
        return Promise.reject( data )
    }
}


// axios Response 处理
export const handleResponseData = ( response ) => {

    const { data, config } = response
    // 在兑换页面，获取用户信息时，不会跳转处理
    const exchangeUserInfo = config.url.includes( '/user/info' ) && window.location.pathname === '/exchange'
    if ( !exchangeUserInfo && data.code !== 200 ) {
        return codeHandleFn( data )
    }

    return Promise.resolve( data )
}


export function httpCodeHandle( error ) {
    /* 获取http错误响应码进行提示或逻辑处理 */

    const HttpCodeMsg = new Map( [
        [ 400, '请求错误' ],
        [ 401, '未授权' ],
        [ 403, '拒绝访问' ],
        [ 404, '请求地址出错误' ],
        [ 408, '请求超时' ],
        [ 500, '服务器内部错误' ],
        [ 501, '服务未实现' ],
        [ 502, '网关错误' ],
        [ 503, '服务不可用' ],
        [ 504, '网关超时' ],
        [ 505, 'HTTP版本不受支持' ]
    ] )

    // 解析出http错误码

    const status = get( error, 'response.status' )

    const msg = HttpCodeMsg.get( status )

    const errorText = `${ msg }:${ error.response?.config?.url }` || '未知错误'

    error.msg = errorText

    error.isHttpError = true

    error.code = 'http error'

    return Promise.reject( error )
}

import React from 'react'
import { Row, Col, Card, Button, Input, Form, Modal, Result } from 'antd'
import { useTranslation } from 'react-i18next'

import './deactivate.less'

// hook
import useDeactivate from './deactivate.hook'

const Share = () => {
    const {t} = useTranslation()

    const {
        open,
        confirmLoading,
        rulesForm,
        deactivateStatus,
        openModal,
        hideModal,
        confirmDeactivate
    } = useDeactivate()

    const deactivateSuccess = () => {
        return (
            <Result
                status="success"
                title={t('deactivate.deactivateSuccessTitle') }
                subTitle={t('deactivate.deactivateSuccessTips') }
            />
        )
    }
    return (
        <div className="deactivate-container">
            { deactivateStatus ? deactivateSuccess() :
                <Row gutter={ [ 50, 20 ] } className="form-wrap">
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 12 }>
                        <h1 className="title">{ t('deactivate.title') }</h1>
                        <p className="tips">{ t('deactivate.tips1') }</p>
                        <p className="tips">{ t('deactivate.tips2') }</p>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 12 }>
                        <Card>
                            <Form
                                size="large"
                                name="loginFrom"
                                onFinish={ openModal }>
                                <Form.Item name="account" rules={ rulesForm.account }>
                                    <Input placeholder={ t('deactivate.account') }/>
                                </Form.Item>
                                <Form.Item name="password" rules={ rulesForm.password }>
                                    <Input type="password" placeholder={ t('login.password') }/>
                                </Form.Item>
                                <Form.Item>
                                    <Button style={ {width: '100%'} } type="primary"
                                            htmlType="submit">{ t('deactivate.deactivateAccount') }</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }

            <Modal
                title={ t('deactivate.title') }
                open={ open }
                onOk={ confirmDeactivate }
                onCancel={ hideModal }
                confirmLoading={ confirmLoading }
                okText={ t('deactivate.confirmDeactivate') }
                cancelText={ t('common.cancel') }
            >
                { t('deactivate.confirmTips') }
            </Modal>
        </div>
    )
}

export default Share

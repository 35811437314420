import React from 'react'
import { Modal, Form, Input, Button,Spin } from 'antd'
// images src
import logoSrc from '@/assets/images/logo.png'
import ExchangeIcon1 from '@/assets/images/exchange/icon-1.svg'
import ExchangeIcon2 from '@/assets/images/exchange/icon-2.svg'
import ExchangeIcon3 from '@/assets/images/exchange/icon-3.svg'

import './exchange.less'
import useExchange from './exchange.hook'

const Exchange = () => {
    const {
        inputCodeRef,
        pageLoading,
        pageData,
        isUserToken,
        userInfo,
        open,
        rulesForm,
        confirmLoading,
        modalType,
        modalTitle,
        openModal,
        hideModal,
        sendSignIn,
        sendActivityExchange,
    } = useExchange()
    return (
        <>
            <header className="exchange-header">
                <img src={ logoSrc } width="106" alt="logo"/>
            </header>

            <main className="exchange-main">
                <div className="exchange-content">
                    <div className="exchange-content-shell">
                        <Spin size="large" spinning={ pageLoading }>
                            <div className="exchange-content-form">
                                <div className="exchange-content-form-input">
                                    <mark>
                                        <span>兑换码</span>
                                    </mark>
                                    <input ref={ inputCodeRef } type="text" placeholder="请输入兑换码"
                                           placeholder-class="input-placeholder"/>
                                </div>

                                <p className="exchange-content-form-tips">亲爱的用户：{
                                    isUserToken
                                        ? <em>{ userInfo.email }</em>
                                        : <em onClick={ () => openModal( 'from' ) }>请登录</em>
                                }
                                </p>

                                <div className="exchange-content-form-confirm" onClick={ sendActivityExchange }>
                                    <span>确定兑换</span></div>

                                <ul className="exchange-content-form-process">
                                    <li>
                                        <figure>
                                            <img src={ ExchangeIcon1 } alt="ExchangeIcon"/>
                                        </figure>
                                        <p>登录账号</p>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src={ ExchangeIcon2 } alt="ExchangeIcon"/>
                                        </figure>
                                        <p>输入兑换码</p>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src={ ExchangeIcon3 } alt="ExchangeIcon"/>
                                        </figure>
                                        <p>自动到账<br/>APP上查看</p>
                                    </li>
                                </ul>
                            </div>
                        </Spin>
                    </div>
                </div>
            </main>

            <Modal title={
                <div style={ { textAlign: 'center' } }>
                    <strong>{ modalTitle }</strong>
                </div>
            }
                   closeIcon={ <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                    fill="none">
                       <g clip-path="url(#clip0_4329_413)">
                           <circle cx="10" cy="10" r="10" fill="#F2F2F2"/>
                           <path
                               d="M14.305 5.68933C14.3906 5.77501 14.4387 5.89121 14.4387 6.01236C14.4387 6.13351 14.3906 6.24971 14.305 6.33539L10.6433 9.99699L14.305 13.6589C14.3486 13.701 14.3834 13.7515 14.4074 13.8072C14.4313 13.863 14.4439 13.9229 14.4444 13.9836C14.445 14.0442 14.4334 14.1044 14.4104 14.1606C14.3874 14.2167 14.3535 14.2677 14.3106 14.3106C14.2677 14.3535 14.2167 14.3874 14.1606 14.4104C14.1044 14.4334 14.0442 14.445 13.9836 14.4444C13.9229 14.4439 13.8629 14.4313 13.8072 14.4074C13.7515 14.3834 13.701 14.3486 13.6589 14.305L9.99721 10.6431L6.33585 14.3047C6.29342 14.3471 6.24306 14.3808 6.18762 14.4037C6.13219 14.4267 6.07277 14.4386 6.01277 14.4386C5.95276 14.4386 5.89334 14.4268 5.83789 14.4038C5.78245 14.3809 5.73206 14.3472 5.68962 14.3048C5.64718 14.2624 5.61351 14.212 5.59053 14.1566C5.56756 14.1012 5.55572 14.0417 5.55571 13.9817C5.5557 13.9217 5.5675 13.8623 5.59045 13.8069C5.6134 13.7514 5.64705 13.701 5.68947 13.6586L9.35084 9.99699L5.68947 6.33569C5.64703 6.29327 5.61336 6.24291 5.59038 6.18748C5.5674 6.13204 5.55557 6.07263 5.55556 6.01262C5.55553 5.89143 5.60364 5.7752 5.68932 5.68948C5.77499 5.60377 5.89121 5.5556 6.0124 5.55557C6.13359 5.55554 6.24983 5.60366 6.33554 5.68933L9.99721 9.35063L13.6592 5.68933C13.7449 5.60368 13.8611 5.55556 13.9822 5.55556C14.1034 5.55556 14.2196 5.60368 14.3053 5.68933H14.305Z"
                               fill="#ABABAB"/>
                       </g>
                       <defs>
                           <clipPath id="clip0_4329_413">
                               <rect width="20" height="20" fill="white"/>
                           </clipPath>
                       </defs>
                   </svg> }
                   width={ 410 }
                   wrapClassName={ "exchange-modal" }
                   open={ open }
                   onCancel={ hideModal }
                   confirmLoading={ confirmLoading }
                   footer={ null }
            >
                { modalType === 'from' && <Form
                    size="large"
                    name="loginFrom"
                    onFinish={ sendSignIn }

                >
                    <Form.Item name="account" rules={ rulesForm.account }>
                        <Input size="large" placeholder="请输入电子邮箱/手机号码"
                               prefix={ <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 18 18" fill="none">
                                   <path
                                       d="M10.8 9.36C13.9813 9.36 16.56 11.9387 16.56 15.12V15.48C16.56 15.8619 16.4083 16.2282 16.1382 16.4982C15.8682 16.7683 15.5019 16.92 15.12 16.92H2.88C2.49809 16.92 2.13182 16.7683 1.86177 16.4982C1.59172 16.2282 1.44 15.8619 1.44 15.48V15.12C1.44 11.9387 4.01868 9.36 7.2 9.36H10.8ZM10.8 10.44H7.2C4.65228 10.44 2.58012 12.4758 2.52144 15.0095L2.52 15.12V15.48C2.52001 15.5682 2.55239 15.6533 2.61098 15.7192C2.66957 15.7851 2.75031 15.8272 2.83788 15.8375L2.88 15.84H15.12C15.2082 15.84 15.2933 15.8076 15.3592 15.749C15.4251 15.6904 15.4672 15.6097 15.4775 15.5221L15.48 15.48V15.12C15.48 12.5723 13.4442 10.5001 10.9105 10.4414L10.8 10.44ZM9 1.08C10.9883 1.08 12.6 2.69172 12.6 4.68C12.6 6.66828 10.9883 8.28 9 8.28C7.01172 8.28 5.4 6.66828 5.4 4.68C5.4 2.69172 7.01172 1.08 9 1.08ZM9 2.16C8.33166 2.16 7.69068 2.4255 7.21809 2.89809C6.7455 3.37068 6.48 4.01166 6.48 4.68C6.48 5.34835 6.7455 5.98932 7.21809 6.46191C7.69068 6.9345 8.33166 7.2 9 7.2C9.66835 7.2 10.3093 6.9345 10.7819 6.46191C11.2545 5.98932 11.52 5.34835 11.52 4.68C11.52 4.01166 11.2545 3.37068 10.7819 2.89809C10.3093 2.4255 9.66835 2.16 9 2.16Z"
                                       fill="#ABABAB"/>
                               </svg> }/>
                    </Form.Item>
                    <Form.Item name="password" rules={ rulesForm.password }>
                        <Input type="password" placeholder="请输入登录密码"
                               prefix={ <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 18 18" fill="none">
                                   <path
                                       d="M8.99999 1.08C11.7835 1.08 14.04 3.33648 14.04 6.12V7.2C14.6129 7.2 15.1623 7.42757 15.5673 7.83265C15.9724 8.23773 16.2 8.78713 16.2 9.36V14.76C16.2 15.3329 15.9724 15.8823 15.5673 16.2874C15.1623 16.6924 14.6129 16.92 14.04 16.92H3.95999C3.38712 16.92 2.83772 16.6924 2.43264 16.2874C2.02756 15.8823 1.79999 15.3329 1.79999 14.76V9.36C1.79999 8.78713 2.02756 8.23773 2.43264 7.83265C2.83772 7.42757 3.38712 7.2 3.95999 7.2V6.12C3.95999 3.33648 6.21647 1.08 8.99999 1.08ZM14.04 8.28H3.95999C3.68451 8.27998 3.41944 8.38524 3.21901 8.57422C3.01859 8.76321 2.89795 9.02164 2.88179 9.29664L2.87999 9.36V14.76C2.87997 15.0355 2.98522 15.3005 3.17421 15.501C3.36319 15.7014 3.62163 15.822 3.89663 15.8382L3.95999 15.84H14.04C14.3155 15.84 14.5805 15.7348 14.781 15.5458C14.9814 15.3568 15.102 15.0984 15.1182 14.8234L15.12 14.76V9.36C15.12 9.08452 15.0148 8.81945 14.8258 8.61903C14.6368 8.4186 14.3783 8.29796 14.1033 8.2818L14.04 8.28ZM10.98 11.52C11.1232 11.52 11.2606 11.5769 11.3618 11.6782C11.4631 11.7794 11.52 11.9168 11.52 12.06C11.52 12.2032 11.4631 12.3406 11.3618 12.4418C11.2606 12.5431 11.1232 12.6 10.98 12.6H7.01999C6.87677 12.6 6.73942 12.5431 6.63815 12.4418C6.53688 12.3406 6.47999 12.2032 6.47999 12.06C6.47999 11.9168 6.53688 11.7794 6.63815 11.6782C6.73942 11.5769 6.87677 11.52 7.01999 11.52H10.98ZM8.99999 2.16C6.84755 2.16 5.09651 3.8772 5.04143 6.01632L5.03999 6.12V7.2H12.96V6.12C12.96 3.96756 11.2428 2.21652 9.10367 2.16144L8.99999 2.16Z"
                                       fill="#ABABAB"/>
                               </svg> }/>
                    </Form.Item>
                    <Form.Item>
                        <Button className="exchange-modal-submit" htmlType="submit">立即登录</Button>
                    </Form.Item>
                </Form> }

                {
                    modalType === 'success'
                    && <div className="exchange-modal-success">
                        <div className="account-password">
                            <p>订阅账号：{ userInfo.email }</p>
                            <p>兑换内容：{ pageData.name }（{ pageData.time }天使用时长）</p>
                        </div>
                        <p className="tips">*请前往APP查看</p>
                        <Button className="exchange-modal-submit" onClick={ hideModal }>我知道了</Button>
                    </div>
                }

                { modalType === 'error'
                    && <div className="exchange-modal-error">
                        <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 140 140"
                                 fill="none">
                                <ellipse cx="70" cy="124.444" rx="32" ry="3.33333" fill="#F7E7D8"/>
                                <path
                                    d="M70.1405 36.6104C50.9605 36.6104 35.4205 52.1504 35.4205 71.3304C35.4205 90.5103 50.9605 106.05 70.1405 106.05C89.3205 106.05 104.86 90.5103 104.86 71.3304C104.86 52.1504 89.3205 36.6104 70.1405 36.6104ZM8.89047 91.7703L27.1605 73.5003C27.7905 72.8703 28.7705 72.8703 29.3305 73.5003C29.9605 74.1303 29.9605 75.1103 29.3305 75.6703L11.0605 93.9403C10.4305 94.5703 9.45047 94.5703 8.89047 93.9403C8.26047 93.3803 8.26047 92.4003 8.89047 91.7703Z"
                                    fill="url(#paint0_linear_4329_569)"/>
                                <path d="M0.700012 78.54L11.41 72.73L10.22 82.11L0.700012 78.54Z" fill="#FFB310"/>
                                <path
                                    d="M102.2 30.66C102.2 32.55 103.67 34.0201 105.56 34.0201C107.45 34.0201 108.92 32.55 108.92 30.66C108.92 28.77 107.45 27.3 105.56 27.3C103.74 27.3 102.2 28.77 102.2 30.66Z"
                                    fill="#FFE791"/>
                                <path
                                    d="M35.35 34.3699L42.98 31.4299L40.04 39.0599L32.41 41.9999L35.35 34.3699ZM35.35 39.0599L32.27 31.5699L39.76 34.6499L42.84 42.1399L35.35 39.0599Z"
                                    fill="#FF3B66"/>
                                <path
                                    d="M117.04 101.36L125.23 98.21L122.08 106.4L113.89 109.55L117.04 101.36ZM117.04 106.4L113.75 98.35L121.8 101.64L125.09 109.69L117.04 106.4Z"
                                    fill="#FFE791"/>
                                <path
                                    d="M111.65 73.3599L130.27 54.7399C130.83 54.1799 131.67 54.2499 132.23 54.7399C132.72 55.2999 132.72 56.1399 132.23 56.6999L113.61 75.3199C113.05 75.8799 112.21 75.8099 111.65 75.3199C111.16 74.7599 111.16 73.9199 111.65 73.3599Z"
                                    fill="#FFB310"/>
                                <path
                                    d="M108.99 84.07L136.92 56.14C137.48 55.58 138.32 55.65 138.88 56.14C139.37 56.7 139.37 57.54 138.88 58.1L110.95 86.03C110.39 86.59 109.55 86.52 108.99 86.03C108.5 85.47 108.5 84.63 108.99 84.07Z"
                                    fill="#FF3B66"/>
                                <path
                                    d="M83.86 81.6901L80.92 84.7001C80.36 85.2601 79.52 85.2601 78.96 84.7001L70.56 76.3001L62.16 84.7001C61.6 85.2601 60.76 85.2601 60.2 84.7001L57.19 81.6901C56.63 81.1301 56.63 80.2901 57.19 79.7301L65.59 71.3301L57.19 62.9301C56.63 62.3701 56.63 61.5301 57.19 60.9701L60.13 58.0301C60.69 57.4701 61.53 57.4701 62.09 58.0301L70.49 66.4301L78.89 58.0301C79.45 57.4701 80.29 57.4701 80.85 58.0301L83.79 60.9701C84.35 61.5301 84.35 62.3701 83.79 62.9301L75.39 71.3301L83.79 79.7301C84.42 80.2901 84.42 81.1301 83.86 81.6901Z"
                                    fill="white"/>
                                <defs>
                                    <linearGradient id="paint0_linear_4329_569" x1="43.1673" y1="36.166" x2="65.334"
                                                    y2="106.166" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.121428" stop-color="#FFE26D"/>
                                        <stop offset="1" stop-color="#FF3B66"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </figure>
                        <Button className="exchange-modal-submit" onClick={ hideModal }>我知道了</Button>
                    </div> }
            </Modal>
        </>
    )
}

export default Exchange

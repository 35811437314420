import to from 'await-to-js'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { message } from 'antd'

// api
import { signIn, userinfo } from '@/api/user'
import { activityExchange } from '@/api/exchange'

// store
import { useDispatch, useSelector } from 'react-redux'
import { updateToken, updateAffiliate, updateUserInfo, updateOrderInfo } from '@/store/modules/userStore'

const useExchange = () => {
    const location = useLocation()
    const history = useHistory()

    // redux
    const dispatch = useDispatch()
    const userToken = useSelector( state => state.userStore.token )
    const userInfo = useSelector( state => state.userStore.userInfo )

    // input Ref
    const inputCodeRef = useRef( null )

    // 用户是否自带token 数据
    const [ isUserToken, setIsUserToken ] = useState( false )
    const [ pageLoading, setPageLoading ] = useState( false )
    const [ pageData, setPageData ] = useState( {
        name: '',
        time: '',
    } )

    // 弹窗
    const [ open, setOpen ] = useState( false )
    const [ modalType, setModalType ] = useState( 'success' )
    const [ modalTitle, setModalTitle ] = useState( '登录 jiashu' )
    const [ confirmLoading, setConfirmLoading ] = useState( false )


    const rulesForm = {
        account: [
            {
                required: true,
                message: '请输入电子邮箱/手机号码'
            }
        ],
        password: [
            {
                required: true,
                message: '请输入登录密码'
            }
        ]
    }

    const hideModal = () => {
        setOpen( false )
    }

    const openModal = ( str ) => {
        setOpen( true )
        setModalType( str )
        const titleEnums = {
            'from': '登录 jiashu',
            'success': '兑换成功',
            'error': '兑换失败',
        }

        setModalTitle( titleEnums[ str ] )
    }


    // 获取用户信息
    const sendUserinfo = async () => {
        const [ err, res ] = await to( userinfo() )
        if ( err ) return
        if ( res.code === 200 ) {
            const { data } = res
            dispatch( updateUserInfo( data ) )
        } else {
            dispatch( updateToken( null ) )
            setIsUserToken( false )
            history.push( '/exchange' )

        }


    }

    // 处理路由数据
    const handleRouterParams = () => {
        setPageLoading( true )
        const queryParams = new URLSearchParams( location.search )
        const from = queryParams.get( 'from' )
        const form = queryParams.get( 'form' )
        const token = queryParams.get( 'token' )
        const urlQuery = from || form || token
        setPageLoading( false )
        if ( !userToken && !urlQuery ) return
        setIsUserToken( true )
        if ( urlQuery ) dispatch( updateToken( urlQuery ) )
        sendUserinfo().then()
    }

    // 用户登录
    const sendSignIn = async ( values ) => {
        setConfirmLoading( true )
        const [ err, res ] = await to( signIn( { ...values, type: 1 } ) )
        setConfirmLoading( false )

        if ( err ) return

        const { data, msg } = res
        message.success( msg )

        dispatch( updateToken( data.token ) )
        dispatch( updateUserInfo( data.userInfo ) )
        dispatch( updateAffiliate( data.affiliate ) )
        dispatch( updateOrderInfo( data.orderInfo ) )
        setOpen( false )
        setIsUserToken( true )
    }
    // 兑换
    const sendActivityExchange = async () => {
        if ( !userToken ) return message.error( '请先登录' )
        if ( !inputCodeRef.current.value ) return message.error( '请输入兑换码' )
        const [ err, res ] = await to( activityExchange( { code: inputCodeRef.current.value } ) )
        if ( err ) {
            openModal( 'error' )
            return
        }

        const { data, msg } = res
        message.success( msg )
        setPageData( {
            name: data.subscribe_name,
            time: data.duration / 24,
        } )
        openModal( 'success' )
    }

    useEffect( () => {
        handleRouterParams()
    }, [ location ] )

    return {
        inputCodeRef,
        pageLoading,
        pageData,
        isUserToken,
        userInfo,
        open,
        rulesForm,
        confirmLoading,
        modalType,
        modalTitle,
        openModal,
        hideModal,
        sendSignIn,
        sendActivityExchange,

    }
}

export default useExchange
